<template>
  <validation-observer ref="tambahTopUp">
    <b-card title="Topup Manual">
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >

            <b-form-group
              label="Masukan No Hp"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="No Hp"
                rules="required"
              >
                <b-form-input
                  v-model="form.no_hp"
                  :state="errors.length > 0 ? false:null"
                />
              </validation-provider></b-form-group>
            <b-form-group
              label="Masukan Nominal"
              label-for="input-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Nominal"
                rules="required"
              >
                <b-form-input
                  v-model="form.jumlah"
                  :state="errors.length > 0 ? false:null"
                />
              </validation-provider></b-form-group>
            <b-form-group
              label="Pilih Aplikasi"
              label-for="input-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Aplikasi"
                rules="required"
              >
                <v-select
                  v-model="form.aplikasi"
                  label="label"
                  placeholder="Pilih Aplikasi"
                  :options="mitracus"
                  :state="errors.length > 0 ? false:null"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider></b-form-group>

            <b-form-group
              label="Masukkan deskripsi"
              label-for="input-4"
            >
              <validation-provider
                #default="{ errors }"
                name="Deskripsi"
                rules="required"
              >
                <b-form-textarea
                  v-model="form.keterangan"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Masukkan deskripsi"
                  rows="3"
                  max-rows="6"
                />
              </validation-provider></b-form-group>

            <b-button
              variant="success"
              @click="topUp()"
            >
              <span v-if="!loading">TopUp</span>
              <b-spinner
                v-else
                small
              />

            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </validation-observer>
</template>
<script>
import {
  BCard, BCol, BRow, BButton, BFormGroup, BFormInput, BFormTextarea, BSpinner,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebase from 'firebase/app'

export default {
  name: 'TopUp',
  components: {
    BCard,
    BCol,
    BRow,
    // BBadge,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,

  },
  data() {
    return {
      form: {
        no_hp: '',
        jumlah: '',
        aplikasi: '',
        keterangan: '',

      },
      loading: false,
      required,
      mitracus: [
        'mitra', 'customer',

      ],

    }
  },
  methods: {
    topUp() {
      this.$refs.tambahTopUp.validate()
        .then(success => {
          if (success) {
            this.loading = true
            if (this.form.no_hp.charAt(0) === 0) {
              this.form.no_hp = this.form.no_hp.substring(1)
            }

            const optionPost = {
              url: 'https://us-central1-mecare-life.cloudfunctions.net/manualTopUp',
              method: 'POST',
              data: {
                no_hp: this.form.no_hp,
                jumlah: this.form.jumlah,
                aplikasi: this.form.aplikasi,
                keterangan: this.form.keterangan,
                waktu: firebase.firestore.FieldValue.serverTimestamp(),

              },
            }

            this.$axios(optionPost)
              .then(res => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data,
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })

                this.form.no_hp = ''
                this.form.jumlah = ''
                this.form.aplikasi = ''
                this.form.keterangan = ''
                this.loading = false
              })
          }
        })
    },
  },
}
</script>
